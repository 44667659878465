import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: { customProperties: true },
		themes: {
			light: {
				primary: '#50A82A',
				secondary: '#673ab7',
				tertiary: '#673AB7',
				accent: '#8c9eff',
				error: '#D50000',
				warning: '#FCC00D',
				root: '#e2e2e2',
				white: '#ffffff',
				pink: '#E91E63',
				text: '#121212',
			},
			dark: {
				secondary: '#673AB7',
				tertiary: colors.orange.base,
				accent: '#8c9eff',
				root: '#121212',
				white: '#121212',
				error: '#D50000',
				warning: '#FCC00D',
				pink: '#E91E63',
				text: '#ffffff',
			},
		},
	},
});
