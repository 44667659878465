<template>
	<div class="internal-container">
		<v-app-bar color="primary darken-2" dark>
			<v-toolbar-title>All Tickets</v-toolbar-title>
			<v-spacer></v-spacer>
			<div style="display: flex" class="mr-n3">
				<v-text-field
					style="align-self: center"
					v-model="searchTickets"
					label="Search"
					@keyup.enter="
						() => {
							isTicketSearch ? FindTicket() : GetTickets();
						}
					"
					@click:clear="
						() => {
							searchTickets = '';
						}
					"
					@input="HandleSearchTicketsChange"
					placeholder="Filter Data"
					persistent-placeholder
					outlined
					dense
					clearable
					hide-details="auto"
				></v-text-field>

				<v-menu
					v-model="showFilter"
					:close-on-content-click="true"
					:close-on-click="true"
					:nudge-width="200"
					max-width="25%"
					min-width="300px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							<v-icon>mdi-filter-variant</v-icon>
						</v-btn>
					</template>

					<v-card>
						<v-toolbar flat dense>
							<v-toolbar-title>Filters</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-checkbox
								id="closed-ticket-checkbox"
								v-model="includeClosed"
								hide-details
								label="Include Closed?"
								class="py-2 px-3"
							>
							</v-checkbox>
						</v-toolbar>

						<v-text-field
							class="py-2 px-3"
							v-model="findTicketSearch"
							outlined
							dense
							label="Find"
							@keyup.enter="
								() => {
									FindTicket();
									searchTickets = '';
								}
							"
							@click:clear="
								() => {
									findTicketSearch = '';
									searchTickets = '';
								}
							"
							@input="HandleFindTicketsChange"
							placeholder="Id / AccountNo / Postcode / Description"
							clearable
							persistent-placeholder
							hide-details=""
						>
						</v-text-field>
						<GenericSelect
							class="py-2 px-3"
							id="Select-User"
							:searchItems.sync="staffStore.getUsers"
							v-model="staffStore.selectedUsers"
							:type="'User'"
							:listName="'FullName'"
							:listSubName="'Position'"
							:listValue="'UserId'"
							:multiple="true"
							@change-item="delayedChangeUsers"
							:loading="staffStore.getUsers.length === 0"
							:smallChips="true"
							:clearable="true"
							:clearableChips="true"
						>
						</GenericSelect>

						<GenericSelect
							class="py-2 px-3"
							id="Select-Ticket-Status"
							:searchItems.sync="staffStore.ticketStatusArray"
							v-model="staffStore.selectedStatuses"
							:type="'Status'"
							:listName="'Name'"
							:listSubName="''"
							:listValue="'Value'"
							:multiple="true"
							@change-item="
								() => {
									delayedGetGroupedProblems();
									SetSessionStorage(
										sessionStorageKeys.SelectedStatuses,
										staffStore.selectedStatuses
									);
								}
							"
							:loading="false"
							:smallChips="true"
							:clearableChips="true"
						>
						</GenericSelect>

						<GenericSelect
							class="py-2 px-3"
							id="Select-Ticket-Code"
							:searchItems.sync="staffStore.ticketCodes"
							v-model="staffStore.selectedTicketCodes"
							:type="'Ticket Code'"
							:listName="'Name'"
							:listValue="'Code'"
							:rightTopLabel="'InProgress'"
							:rightBottomLabel="'Raised'"
							:multiple="true"
							@change-item="
								() => {
									SetSessionStorage(
										sessionStorageKeys.SelectedTicketCodes,
										staffStore.selectedTicketCodes
									);
									delayedGetGroupedProblems();
								}
							"
							:loading="false"
							:smallChips="true"
							:clearableChips="true"
						>
						</GenericSelect>

						<div style="display: flex" class="py-2 px-3">
							<date-picker-menu
								style="width: 50%"
								class="pr-2"
								v-model="startDate"
								@input="SetSessionStorage(sessionStorageKeys.SelectedStartDate, startDate)"
								:max.sync="endDate"
								:label="'Start Date'"
								:clearable="true"
								@click:clear="
									() => {
										startDate = '';
										SetSessionStorage(sessionStorageKeys.SelectedStartDate, startDate);
									}
								"
							></date-picker-menu>
							<date-picker-menu
								style="width: 50%"
								class="pl-2"
								v-model="endDate"
								@input="SetSessionStorage(sessionStorageKeys.SelectedEndDate, endDate)"
								:min.sync="startDate"
								:label="'End Date'"
								:clearable="true"
								@click:clear="
									() => {
										endDate = '';
										SetSessionStorage(sessionStorageKeys.SelectedEndDate, endDate);
									}
								"
							></date-picker-menu>
						</div>

						<v-divider></v-divider>

						<v-card-actions style="flex-direction: row-reverse">
							<v-btn
								color="primary"
								:disabled="isLoading"
								text
								outlined
								@click="GetGroupedProblems()"
							>
								Filter
							</v-btn>
							<v-btn text @click="showFilter = false" color="orange"> Cancel </v-btn>
							<v-spacer></v-spacer>
							<v-btn text @click="OnResetFiltersClicked" color="secondary"> Reset </v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</div>
		</v-app-bar>

		<div class="pa-4 layout-container" fluid>
			<v-row style="height: 100%">
				<v-col cols="12" class="pb-0" style="position: relative; height: 100%">
					<v-speed-dial
						fixed
						v-model="fab"
						bottom
						right
						direction="top"
						:open-on-hover="true"
						:transition="'slide-y-reverse-transition'"
					>
						<template v-slot:activator>
							<v-btn v-model="fab" color="primary darken-2" dark fab>
								<v-icon v-if="fab"> mdi-close </v-icon>
								<v-icon v-else> mdi-dots-vertical </v-icon>
							</v-btn>
						</template>
						<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
							<template v-slot:activator="{}">
								<v-btn @click="Navigate()" color="secondary" fab dark small>
									<v-icon>mdi-ticket-confirmation</v-icon>
								</v-btn>
							</template>
							<span>Raise Ticket</span>
						</v-tooltip>
						<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
							<template v-slot:activator="{}">
								<v-btn to="customersupport/tickets/dashboard" color="secondary" fab dark small>
									<v-icon>mdi-view-dashboard</v-icon>
								</v-btn>
							</template>
							<span>Dashboard</span>
						</v-tooltip>

						<v-tooltip value="true" left color="secondary" nudge-top="13" z-index="20">
							<template v-slot:activator="{}">
								<v-btn
									@click="Navigate({ code: ticketCodeEnum.ExtraServiceRequest })"
									color="secondary"
									fab
									dark
									small
								>
									<v-icon>mdi-ticket</v-icon>
								</v-btn>
							</template>
							<span>Raise Extra</span>
						</v-tooltip>
					</v-speed-dial>

					<DataTableWithSlideoutPaginated
						style="height: calc(100%); position: relative; width: 100%"
						:headers="headers"
						:items="tickets"
						v-model="selectedTickets"
						:itemKey="'TicketId'"
						:isLoading="isLoading"
						:customSlots="slots"
						:showSideMenu="true"
						:menuItems="menuItems"
						@row-click="GetTicketDetails"
						@row-click-double="DoubleClick"
						:itemsCount="ticketCount"
						:page.sync="options.page"
						:options.sync="options"
						@update:options="delayedUpdateOptions"
					>
						<template slot="slideout-content">
							<v-toolbar dark color="secondary lighten-1">
								<v-toolbar-title>{{ ticketDetails.TicketId }}</v-toolbar-title>
							</v-toolbar>

							<v-divider></v-divider>

							<v-list dense>
								<v-list-item>
									<v-list-item-content>{{ ticketDetails.Description }} </v-list-item-content>
								</v-list-item>
							</v-list>

							<v-divider></v-divider>

							<v-list subheader three-line style="line-clamp: 3">
								<v-container class="py-0 pt-2">
									<v-row class="py-0">
										<v-col class="py-0">
											<v-subheader class="pa-0">{{ showEmail ? 'Email' : 'Notes' }}</v-subheader>
										</v-col>
										<v-col v-if="showEmail" class="text-right"
											><v-btn icon @click="showEmail = false">
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
								<v-list v-if="!showEmail">
									<v-list-item
										v-for="note in ticketDetails.CustomerNotes"
										class="px-0"
										:inactive="showEmail ? true : false"
										:key="note.CustomerNoteId"
									>
										<v-list-item-content v-if="!showEmail" class="py-0">
											<v-container>
												<v-row>
													<v-col class="py-0 px-0">
														<NoteCard
															@card-clicked="ShowEmail(note)"
															:indicator="note.Priority.Value === notePriorityEnum.Important"
															:indicatorColour="'var(--v-error-base)'"
															:indicatorLabel="note.Priority.Name"
															:note="note"
															flat
															class="py-0"
														>
															<template slot="icon-slot">
																<v-icon v-if="note.EmailType !== emailTypeEnum.None" small
																	>mdi-email</v-icon
																>
																<v-icon v-if="note.NoteType.Value === noteTypeEnum.WhatsApp" small
																	>mdi-whatsapp</v-icon
																>
															</template>
														</NoteCard>
													</v-col>
												</v-row>
											</v-container>
										</v-list-item-content>
									</v-list-item>
								</v-list>

								<v-list-item
									class="dynamic-html d-block"
									v-if="showEmail && selectedNote.EmailType !== emailTypeEnum.None"
									v-html="emailService.BuildHtml(selectedEmail.Body || '', $vuetify.theme.dark)"
								>
								</v-list-item>
							</v-list>
						</template>

						<template v-slot:[`DateCreated.Display`]="slotProps">
							<div v-if="slotProps.data.DateCreated?.Value">
								<strong>{{ 'Created:' }}</strong>
								{{ slotProps.data.DateCreated.Display }}
							</div>
							<div v-if="slotProps.data.LastUpdated?.Value">
								<strong>{{ 'Updated:' }}</strong>
								{{ slotProps.data.LastUpdated.Display }}
							</div>
						</template>

						<template #first-column-indicator="slotProps">
							<v-tooltip left color="secondary" nudge-top="13" z-index="20" internal-activator>
								<template v-slot:activator="{ on, attrs }">
									<div
										v-if="slotProps"
										v-bind="attrs"
										v-on="on"
										class="first-column-indicator"
										style="
											position: relative;
											height: 100%;
											left: 0;
											width: 10px;
											display: flex;
											align-items: center;
										"
										:style="{
											background: GetStatusIndicatorColour(slotProps.data),
										}"
									>
										<v-badge
											v-if="
												MomentDate(slotProps.data.ReminderDate.Value).isBefore(
													MomentDate(nowDate)
												) &&
												MomentDate(slotProps.data.LastUpdated.Value).isBefore(
													MomentDate(slotProps.data.ReminderDate.Value)
												)
											"
											class="ma-0 text--secondary pl-4"
											color="warning"
											:value="
												slotProps.data.ReminderDate.Value
													? MomentDate(slotProps.data.ReminderDate.Value).isBefore(
															MomentDate(nowDate)
													  ) &&
													  MomentDate(slotProps.data.LastUpdated.Value).isBefore(
															MomentDate(slotProps.data.ReminderDate.Value)
													  )
													: false
											"
										>
											<template v-slot:badge>
												<v-icon small>mdi-clock-alert-outline</v-icon>
											</template>
											<strong>{{ slotProps.data ? slotProps.data.TicketId : '' }}</strong>
										</v-badge>
										<v-badge
											v-else-if="
												slotProps.data.CustomerNotes[0]?.EmailType === emailTypeEnum.Incoming ||
												(slotProps.data.CustomerNotes[0]?.IsPublicFacing &&
													slotProps.data.CustomerNotes[0]?.NoteType.Value ===
														noteTypeEnum.CustomerCreated)
											"
											class="ma-0 text--secondary pl-4"
											color="warning"
											:value="
												slotProps.data.CustomerNotes[0]?.EmailType === emailTypeEnum.Incoming ||
												(slotProps.data.CustomerNotes[0]?.IsPublicFacing &&
													slotProps.data.CustomerNotes[0]?.NoteType.Value ===
														noteTypeEnum.CustomerCreated)
											"
										>
											<template v-slot:badge>
												<v-icon small>mdi-chat-alert-outline</v-icon>
											</template>
											<strong>{{ slotProps.data ? slotProps.data.TicketId : '' }}</strong>
										</v-badge>
										<v-badge
											v-else
											class="ma-0 text--secondary pl-4"
											color="warning"
											:value="slotProps.data.Urgency?.Value === urgencyEnum.Urgent ? true : false"
										>
											<template v-slot:badge>
												<v-icon small>mdi-pin</v-icon>
											</template>
											<strong>{{ slotProps.data ? slotProps.data.TicketId : '' }}</strong>
										</v-badge>
									</div>
								</template>
								<span>{{ slotProps.data.Status.Name }}</span>
							</v-tooltip>
						</template>
					</DataTableWithSlideoutPaginated>
				</v-col>
			</v-row>

			<ConfirmDialog
				v-model="dialogOpts.show"
				:message="dialogOpts.message"
				:title="dialogOpts.title"
				@cancel="dialogOpts.show = false"
				@confirm="dialogOpts.callback"
				:maxWidth="800"
				:isValid="isConfirmFormValid"
			>
				<template v-slot:extend>
					<v-card class="px-4 pt-1" flat v-if="dialogOpts.title === 'Hide Tickets'">
						<v-form ref="confirmForm" v-model="isConfirmFormValid">
							<v-container class="px-0">
								<v-row no-gutters>
									<v-col>
										<DatePickerMenu
											v-model="hideUntilDate"
											:min.sync="nowDate"
											:label="'Date'"
											class="pr-2"
											:rules="[(v) => !!v || 'Date is required']"
										></DatePickerMenu>
									</v-col>
									<v-col>
										<TimePickerMenu
											class="pl-2"
											v-model="hideUntilTime"
											:label="'Time'"
											:rules="[(v) => !!v || 'Time is required']"
										>
										</TimePickerMenu>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-textarea
											v-model="hideReason"
											label="Search"
											placeholder="Reason"
											persistent-placeholder
											outlined
											dense
											counter="255"
											hide-details="auto"
											:rules="[
												(v) => !!v || 'Please enter a reason',
												(v) => (v && v.length < 255) || 'Please enter less than 255 characters',
												(v) => (v && v.length > 5) || 'Please enter more than 5 characters',
											]"
										></v-textarea>
									</v-col>
								</v-row>
							</v-container>
						</v-form>
					</v-card>
					<v-card
						class="px-4 pt-1"
						flat
						v-if="dialogOpts.title.toLowerCase().includes('close tickets')"
					>
						<v-form ref="confirmForm" v-model="isConfirmFormValid">
							<v-textarea
								v-model="closeTicketReason"
								label="Reason"
								placeholder="Enter Reason"
								persistent-placeholder
								outlined
								dense
								hide-details="auto"
								:rules="[
									(v) => !!v || 'Please enter a Reason',
									(v) => (v && v.length > 4) || 'Please Enter more than 4 characters',
								]"
							></v-textarea>
						</v-form>
					</v-card>
					<v-card class="px-4 pt-1" flat v-if="dialogOpts.title.toLowerCase().includes('site')">
						<v-form ref="confirmForm" v-model="isConfirmFormValid">
							<v-text-field
								@input="delayedGetSites"
								v-model="searchSiteText"
								label="Search"
								placeholder="Enter Postcode"
								persistent-placeholder
								outlined
								dense
								clearable
								hide-details="auto"
								:rules="[
									(v) => !!v || 'Please enter a postcode',
									(v) => (v && v.length > 4) || 'Please Enter more than 4 characters',
								]"
							></v-text-field>

							<GenericSelect
								class="pt-4"
								id="Select-Site"
								:searchItems.sync="sites"
								v-model="selectedSite"
								:disabled="isConfirmFormValid"
								:type="'Site'"
								:listName="'SiteName'"
								:listSubName="'SiteReference'"
								:listAdditionalSubName="'Customer.CustomerName'"
								:rightTopLabel="'Address.AddressLine1'"
								:rightMiddleLabel="'Address.Town'"
								:rightBottomLabel="'Address.Postcode'"
								:listValue="'SiteId'"
								:loading="isLoading"
								:rules="[
									(v) => !!v || 'Site is required',
									(v) => (v && !!v.SiteId) || 'Site is required',
								]"
								:clearable="true"
								:smallChips="true"
							>
							</GenericSelect>
						</v-form>
					</v-card>

					<v-card class="px-4 pt-1" flat v-if="dialogOpts.title.toLowerCase().includes('urgency')">
						<v-form ref="confirmForm" v-model="isConfirmFormValid">
							<GenericSelect
								class="pt-4"
								id="Select-Urgency"
								:searchItems="urgencyArray"
								v-model="selectedUrgency"
								:type="'Urgency'"
								:listName="'Name'"
								:listValue="'Value'"
								:loading="isLoading"
								:rules="[(v) => !!v || 'Please select an Urgency']"
								:clearable="true"
								:smallChips="true"
							>
							</GenericSelect>
						</v-form>
					</v-card>

					<v-card class="px-4 pt-1" flat v-if="dialogOpts.title.toLowerCase().includes('workload')">
						<v-form ref="confirmForm" v-model="isConfirmFormValid">
							<v-container>
								<v-row>
									<v-col cols="6">
										<div v-if="isTeamDataLoading">
											<v-skeleton-loader
												v-for="i in [1, 2, 3, 4, 5]"
												:key="i"
												v-bind="attrs"
												type=" list-item-avatar,  list-item: text"
											></v-skeleton-loader>
										</div>
										<div v-else>
											<v-card-text class="font-weight-bold pa-0 pb-2"
												>Choose a new assigned agent
											</v-card-text>
											<v-list style="height: 33vh; overflow-y: auto">
												<v-list-item
													@click="AssignUser(user)"
													class="pl-0"
													ripple="true"
													style="cursor: pointer"
													v-for="user in teamData"
													:key="`${user.userId}-${user.FullName}`"
												>
													<v-list-item-content>
														<v-list-item-title
															><b>{{ user.FullName }}</b></v-list-item-title
														>
														<v-list-item-subtitle class="text--secondary">
															<i>{{ user.UserGroups.map((x) => x.Name).join(', ') }}</i>
														</v-list-item-subtitle>
														<v-list-item-subtitle class="text--primary"
															>In Progress:<b>{{ user.inProgress }}</b> Raised:
															<b>{{ user.raised }}</b></v-list-item-subtitle
														>
													</v-list-item-content>
												</v-list-item>
											</v-list>
										</div>
									</v-col>
									<v-col cols="6">
										<v-card-text class="font-weight-bold pa-0 pb-2">Select any user </v-card-text>

										<GenericSelect
											class="mt-4"
											id="Select-User-Modal"
											:searchItems.sync="staffStore.getUsers"
											v-model="selectedStatsUser"
											:type="'User'"
											:listName="'FullName'"
											:listSubName="'Position'"
											:listValue="'UserId'"
											:rules="[
												(v) => !!v || 'User is required',
												(v) => (v && !!v.UserId) || 'User is required',
											]"
											:smallChips="true"
											:loading="false"
										>
										</GenericSelect>
									</v-col>
								</v-row>
							</v-container>
						</v-form>
					</v-card>
				</template>
			</ConfirmDialog>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '/src/styles/base-styles.scss';
@import 'Tickets.scss';
</style>

<script lang="ts">
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Vue, Watch } from 'vue-property-decorator';
import { BaseService } from '@/services/BaseService';
import { isLoading$ } from '@gz/api';

import DataTableWithSlideoutPaginated from '@gz/components/src/lib-components/Generic/DataTableWithSlideoutPaginated.vue';
import ConfirmDialog from '@gz/components/src/lib-components/Dialogs/ConfirmDialog.vue';
import DatePickerMenu from '@gz/components/src/lib-components/Menu/DatePickerMenu.vue';
import GenericSelect from '@gz/components/src/lib-components/Selects/GenericSelect.vue';
import TimePickerMenu from '@gz/components/src/lib-components/Menu/TimePickerMenu.vue';
import IndicatorWithTooltip from '@gz/components/src/lib-components/DataDisplay/Indicators/IndicatorWithTooltip.vue';
import NoteCard from '@gz/components/src/lib-components/Cards/Notes/NoteCard.vue';

import {
	IUser,
	ICustomer,
	ISite,
	SlotItem,
	MenuItem,
	ReadTicket,
	CreateTicket,
	Site,
	User,
	UpdateTicket,
	EnumValueType,
	ReadServiceItem,
} from 'greenzonegateway.classes/lib/classes';
import { userStore } from '@/store/user/userPinia';
import { staffStore } from '@/store/staff/staff';

import { nameof } from '@/main';
import { TicketStatusEnum } from 'greenzonegateway.classes/lib/classes/tickets/enums/TicketEnumStatus';
import { TicketEnumStatusColours } from 'greenzonegateway.classes/lib/classes/tickets/enums/TicketEnumStatusColours';
import { TicketCodeEnum } from 'greenzonegateway.classes/lib/classes/tickets/enums/TicketCodeEnum';
import { NotePriorityEnum } from 'greenzonegateway.classes/lib/classes/tickets/enums/NotePriorityEnum';
import { NoteTypeEnum } from 'greenzonegateway.classes/lib/classes/tickets/enums/NoteType';
import moment from 'moment';
import { IReadTicket } from 'greenzonegateway.classes/lib/classes/tickets/interfaces/IReadTicket';
import { IReadServiceItem } from 'greenzonegateway.classes/lib/classes/service/serviceItems/interfaces/IReadServiceItem';
import { IReadNote } from 'greenzonegateway.classes/lib/classes/tickets/notes/IReadNote';
import { CreateNote } from 'greenzonegateway.classes/lib/classes/tickets/notes/CreateNote.';
import { EmailTypeEnum } from 'greenzonegateway.classes/lib/classes/Generic/EmailType';
import { IEmail } from 'greenzonegateway.classes/lib/classes/tickets/interfaces/IEmail';
import { EmailService } from '@/services/EmailService';
import { debounce } from 'debounce';
import { TicketUrgencyEnum } from 'greenzonegateway.classes/lib/classes/tickets/enums/TicketUrgencyEnum';
import { RelativeDateEnum } from 'greenzonegateway.classes/lib/classes/enums/RelativeDateEnum';

@Component({
	components: {
		DataTableWithSlideoutPaginated,
		DatePickerMenu,
		GenericSelect,
		ConfirmDialog,
		TimePickerMenu,
		IndicatorWithTooltip,
		NoteCard,
	},
})
export default class Tickets extends Vue {
	//Subscription Props
	loadingSubscription: any;

	//Visibilty Props
	isLoading = true;
	userStore = userStore();
	staffStore = staffStore();

	//Services
	service = new BaseService();
	emailService = new EmailService();

	//3rd Party Props
	$appInsights: any;

	// Datatable Props
	page = 1;
	itemsPerPage = 20;
	pageCount = 0;

	showEmail = false;
	selectedNote: IReadNote = new CreateNote() as any;
	notePriorityEnum = NotePriorityEnum;
	ticketCodeEnum = TicketCodeEnum;
	emailTypeEnum = EmailTypeEnum;
	urgencyEnum = TicketUrgencyEnum;

	urgencyArray: any = Object.keys(TicketUrgencyEnum)
		.filter((v) => isNaN(Number(v)))
		.map((Name: any) => {
			var val = TicketUrgencyEnum[Name as keyof typeof TicketUrgencyEnum];
			Name = Name?.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
			return {
				Value: val,
				Name,
			};
		});

	closeTicketReason = '';
	noteTypeEnum = NoteTypeEnum;

	showFilter = false;

	@Watch('dialogOpts.show')
	onDialogClose(current: boolean) {
		if (!current) {
			this.hideUntilDate = null;
			this.hideUntilTime = null;
			(this.$refs.confirmForm as any).reset();
		}
	}

	//Props
	userData: any;

	tickets: IReadTicket[] = [];
	ticketsBackup: IReadTicket[] = [];
	selectedTicket: IReadTicket = {} as any;
	selectedTickets: IReadTicket[] = [];
	ticketDetails: IReadTicket = new ReadTicket();
	isSlideoutVisible?: boolean;
	selectedEmail: IEmail | undefined = {} as any;

	dialogOpts = {
		show: false,
		message: '',
		title: '',
		callback: {},
	};

	@Watch('selectedTickets')
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	onChange(newValue: any) {
		let item = null;

		if (newValue.length == 2) {
			item = this.menuItems.find((xx) => xx.Name === 'Merge');

			if (item) {
				item.Disabled = false;
			}
		} else {
			item = this.menuItems.find((xx) => xx.Name === 'Merge');

			if (item) {
				item.Disabled = true;
			}
		}

		if (this.staffStore.selectedStatuses.some((xx: any) => xx.Value === TicketStatusEnum.Hidden)) {
			if (item) {
				if (!this.headers.some((header) => header.text === 'Hidden Until')) {
					this.headers.push({
						text: 'Hidden Until',
						value: 'ReminderDate.Display',
						width: '10%',
					});
				}
			}
		} else {
			if (item) {
				this.headers = this.headers.filter((xx) => xx.text !== 'Hidden Until');
			}
		}

		if (newValue.length > 1) {
			item = this.menuItems.find((xx) => xx.Name === 'Edit');

			if (item) {
				item.Disabled = true;
			}
		} else {
			item = this.menuItems.find((xx) => xx.Name === 'Edit');

			if (item) {
				item.Disabled = false;
			}
		}
	}

	users: IUser[] = [];
	usersBackup: any[] = [];

	problemCodeSelected?: number;
	startDate: any = null;
	endDate: any = null;
	hideUntilDate: any | undefined;
	nowDate: any = moment().format('YYYY-MM-DD');
	hideUntilTime: any | undefined;
	hideReason = '';

	search = '';
	findTicketSearch = '';
	searchTickets = '';
	searchUsers = '';
	includeClosed = false;
	showGridCheckboxes = false;
	showSelectSite = false;

	sites: ISite[] = [];
	selectedSite: ISite = new Site();
	selectedUrgency: EnumValueType<TicketUrgencyEnum> = {
		Value: TicketUrgencyEnum.Medium,
		Name: TicketUrgencyEnum[TicketUrgencyEnum.Medium],
	};
	searchSiteText = '';
	isConfirmFormValid = false;

	selectedStatsUser: IUser | any = new User();

	fab: any = false;
	delayedFilterTickets = debounce(this.FilterTickets, 200);
	delayedFindTicket = debounce(this.FindTicket, 500);
	delayedUpdateOptions = debounce(this.UpdateOptions, 300);
	delayedChangeUsers = debounce(this.ChangeUser, 500);
	delayedChangeStatus = debounce(this.GetTickets, 500);
	delayedGetSites = debounce(this.GetSites, 500);
	delayedGetGroupedProblems = debounce(this.GetGroupedProblems, 500);
	isTeamDataLoading = false;
	teamData: any = [];

	// t2: Omit<Ticket, 'TicketId'> = new Ticket();

	// Prefilled Props
	headers = [
		{
			text: 'No.',
			align: 'start',
			value: nameof<CreateTicket>('TicketId'),
			groupable: false,
			width: '6%',
		},
		{
			text: 'Category',
			value: 'ProblemCode',
			width: 'auto',
			sortable: false,
		},
		{
			text: 'Service',
			value: 'ServiceItem',
			width: '20%',
		},
		{
			text: 'Customer',
			value: 'Customer',
			width: 'auto',
		},

		{
			text: 'Site',
			value: 'Site',
			width: 'auto',
		},
		{
			text: 'Raised',
			value: 'DateCreated.Display',
			width: '10%',
			slot: true,
		},
	];

	attrs = {
		class: 'mb-6',
		elevation: 2,
	};

	menuItems: MenuItem[] = [
		{
			Name: 'Delete',
			Callback: this.test,
			Disabled: true,
			ShowCount: true,
			Confirm: () => '',
		},
		{
			Name: 'Edit',
			Callback: this.DoubleClick,
			Disabled: false,
			ShowCount: false,
			Confirm: () => '',
		},
		{
			Name: 'Merge',
			Callback: this.ShowMerge,
			Confirm: this.ConfirmMerge,
			Disabled: true,
			ShowCount: true,
		},
		{
			Name: 'Change Site',
			Callback: this.ShowSelectSite,
			Confirm: this.UpdateSite,
			Disabled: false,
			ShowCount: true,
		},
		{
			Name: 'Change User',
			Callback: this.ShowSelectUser,
			Confirm: this.UpdateUser,
			Disabled: false,
			ShowCount: true,
		},
		{
			Name: 'Change Urgency',
			Callback: this.ShowChangeUrgency,
			Confirm: this.UpdateUrgency,
			Disabled: false,
			ShowCount: true,
		},
		{
			Name: 'Close',
			Callback: this.ShowClose,
			Confirm: this.CloseTickets,
			Disabled: false,
			ShowCount: true,
		},
		{
			Name: 'Hide',
			Callback: this.ShowHide,
			Confirm: this.HideTickets,
			Disabled: false,
			ShowCount: true,
		},
	];

	slots: SlotItem[] = [
		{
			Name: 'Site',
			Line1: 'Site.SiteName',
			Line2: 'Site.SiteReference',
			Line3: 'Site.Address.AddressLine1',
			Line4: 'Site.Address.Postcode',
		},
		{
			Name: 'Customer',
			Line1: 'Customer.CustomerName',
			Line2: 'Customer.AccountNumber',
			Line3: 'Customer.CustomerGroup.Name',
			Line4: 'Customer.CustomerGroup.Division.Name',
		},
		{
			Name: 'ServiceItem',
			Line1: 'ServiceItem.Service.ServiceDescription',
			Line2: 'Supplier.SupplierName',
		},
		{
			Name: 'ProblemCode',
			Line1: 'ProblemCode.Name',
			Line2: 'SubCode.Description',
		},
	];
	// below values are for the pagination
	pageSize = 20;
	isTicketSearch = false;
	ticketCount = 0;
	sortBy = '';
	options = {
		page: 1,
		sortBy: [],
		sortDesc: [false],
	};

	test(contextSelectedItem: any): void {
		var test = this.startDate;
		var test2 = this.endDate;
	}

	MomentDate(date: string) {
		return moment(date);
	}

	ConfirmMerge() {
		var source = this.selectedTickets[0];
		var target = this.selectedTickets[1];

		this.service
			.Post<any>(`Tickets/Merge?sourceId=${source.TicketId}&targetId=${target.TicketId}`, {})
			.then(() => {
				this.dialogOpts.show = false;

				this.selectedTickets = this.selectedTickets.filter((xx) => xx.TicketId !== source.TicketId);
				this.tickets = this.tickets.filter((xx) => xx.TicketId !== source.TicketId);
				this.ticketsBackup = this.ticketsBackup.filter((xx) => xx.TicketId !== source.TicketId);
				this.ticketCount = this.ticketCount - 1;

				this.UpdateSidebarCount();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	ShowMerge() {
		var source = this.selectedTickets[0];
		var target = this.selectedTickets[1];

		this.dialogOpts.show = true;
		this.dialogOpts.title = 'Merge Tickets';
		this.dialogOpts.message = `Merge Ticket ${source.TicketId} into ${target.TicketId}`;
		this.dialogOpts.callback = this.ConfirmMerge;
	}

	ShowClose(contextItem: IReadTicket) {
		this.selectedTicket = contextItem;

		this.dialogOpts.show = true;
		this.dialogOpts.title = 'Close Tickets';
		this.dialogOpts.message = `Close Tickets ${(this.selectedTickets.length > 0
			? this.selectedTickets.map((ticket) => {
					return ticket.TicketId;
			  })
			: [contextItem]
		).toString()}`;
		this.dialogOpts.callback = this.CloseTickets;
	}

	CloseTickets() {
		const valid = (this.$refs.confirmForm as any).validate();
		if (valid) {
			let ids =
				this.selectedTickets.length > 0
					? this.selectedTickets.map((ticket) => {
							return ticket.TicketId;
					  })
					: [this.selectedTicket];

			let desc = `<strong>Updated Tickets</strong> - ${ids} </br><strong>Updated By</strong> - ${this.userStore.GetUser.name} </br> </br><strong>Reason - </strong> ${this.closeTicketReason}</br>`;
			this.service
				.Patch<any>('Tickets/Update', {
					noteDescription: desc,
					ticketIds: ids,
					operations: [
						{
							op: 'replace',
							path: '/' + nameof<ReadTicket>('Status'),
							value: TicketStatusEnum.Closed,
						},
					],
				})
				.then(() => {
					this.dialogOpts.show = false;
					this.UpdateSidebarCount();
					this.RemoveTicketsFromList();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}
	ShowHide(contextItem: IReadTicket) {
		this.selectedTicket = contextItem;

		var msg = '';
		if (
			!contextItem.ReminderDate.Value ||
			moment().isAfter(moment(contextItem.ReminderDate.Value))
		) {
			msg = 'Hide';
		} else {
			msg = 'Unhide';
		}

		this.dialogOpts.show = true;
		this.dialogOpts.title = `${msg} Ticket${this.selectedTickets.length > 0 ? 's' : ''}`;
		this.dialogOpts.message = `${msg} Ticket${this.selectedTickets.length > 0 ? 's' : ''} ${(this
			.selectedTickets.length > 0
			? this.selectedTickets.map((ticket) => {
					return ticket.TicketId;
			  })
			: [contextItem]
		).toString()} ${msg === 'Hide' ? 'until:' : ''}`;
		this.dialogOpts.callback = this.HideTickets;
	}

	HideTickets() {
		if (!this.$refs.confirmForm || (this.$refs.confirmForm as any).validate()) {
			var ids =
				this.selectedTickets.length > 0
					? this.selectedTickets.map((ticket) => {
							return ticket.TicketId;
					  })
					: [this.selectedTicket];

			this.service
				.Patch<any>('Tickets/Hide', {
					noteDescription: this.hideReason
						? `<strong>Hidden Ticket Until:</strong> ${moment(this.hideUntilDate).format(
								'Do-MMM-YYYY'
						  )} ${this.hideUntilTime} <br><strong>Reason:</strong> ${this.hideReason}`
						: 'Unhiding Ticket',
					ticketIds: ids,
					operations: [
						{
							op: 'replace',
							path: '/' + nameof<ReadTicket>('ReminderDate'),
							value: this.hideUntilDate
								? moment(this.hideUntilDate).format('YYYY-MM-DD') + 'T' + this.hideUntilTime
								: null,
						},
					],
				})
				.then(() => {
					this.dialogOpts.show = false;

					if (
						!this.staffStore.selectedStatuses.some(
							(xx: any) => xx.Value === TicketStatusEnum.Hidden
						) &&
						this.hideReason
					) {
						this.RemoveTicketsFromList();
					} else {
						this.selectedTickets.map((ticket) => {
							if (this.hideReason) {
								ticket.ReminderDate.Display =
									moment(this.hideUntilDate).format('YYYY-MM-DD') + ' ' + this.hideUntilTime;
								(ticket.ReminderDate.Value as any) = this.hideUntilDate;
							} else {
								ticket.ReminderDate.Display = '';
								(ticket.ReminderDate.Value as any) = undefined;
							}
						});
					}

					this.UpdateSidebarCount();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	UpdateUrgency() {
		if ((this.$refs.confirmForm as any).validate()) {
			var ids =
				this.selectedTickets.length > 0
					? this.selectedTickets.map((ticket) => {
							return ticket.TicketId;
					  })
					: [this.selectedTicket];

			let desc = `<strong>Updated Tickets</strong> - ${ids} </br><strong>Updated By</strong> - ${this.userStore.GetUser.name} </br> </br><strong>Urgency - </strong> ${this.selectedUrgency.Name}</br>`;

			this.service
				.Patch<any>('Tickets/Update', {
					ticketIds: ids,
					noteDescription: desc,
					operations: [
						{
							op: 'replace',
							path: '/' + nameof<CreateTicket>('Urgency'),
							value: this.selectedUrgency.Value,
						},
					],
				})
				.then(() => {
					if (this.selectedUrgency.Value === TicketUrgencyEnum.Urgent) {
						let replace = this.tickets.filter((xx) => (ids as any[]).includes(xx.TicketId));
						this.tickets = this.tickets.filter((xx) => !(ids as any[]).includes(xx.TicketId));
						this.tickets.unshift(...replace);
					} else {
						var index = this.tickets.findIndex(
							(xx) => xx.Urgency?.Value === TicketUrgencyEnum.Medium
						);

						let replace = this.tickets.filter((xx) => (ids as any[]).includes(xx.TicketId));
						this.tickets = this.tickets.filter((xx) => !(ids as any[]).includes(xx.TicketId));
						this.tickets.splice(index === -1 ? this.tickets.length - 1 : index, 0, ...replace);
					}

					this.selectedTickets.forEach((ticket: IReadTicket) => {
						(ticket.Urgency as any) = this.selectedUrgency;
					});
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.dialogOpts.show = false;
				});
		}
	}

	UpdateSite() {
		if ((this.$refs.confirmForm as any).validate()) {
			const updateTicket = new UpdateTicket();

			const unlinkedTicket = JSON.parse(JSON.stringify(this.selectedTicket));

			unlinkedTicket.Site = this.selectedSite as Site;
			updateTicket.SiteId = this.selectedSite.SiteId as number;

			if (this.selectedTicket.Customer?.CustomerId !== unlinkedTicket.Site?.CustomerId) {
				updateTicket.CustomerId = unlinkedTicket.Site?.CustomerId ?? undefined;
				unlinkedTicket.Customer = unlinkedTicket.Site.Customer;
			}

			unlinkedTicket.ServiceItem = new ReadServiceItem();
			updateTicket.ServiceItemId = null;
			const val = updateTicket.GenerateUpdateDetails(unlinkedTicket, this.userStore.GetUser.name);

			var ids =
				this.selectedTickets.length > 0
					? this.selectedTickets.map((ticket) => {
							return ticket.TicketId;
					  })
					: [this.selectedTicket];

			this.service
				.Patch<any>('Tickets/Update', {
					noteDescription: val[0],
					ticketIds: ids,
					operations: val[1],
				})
				.then(() => {
					if (this.selectedTickets.length === 1) {
						this.$router.push({
							name: 'Edit',
							query: { ticketId: this.selectedTicket.TicketId } as any,
						});
					} else {
						this.selectedTickets.forEach((ticket: IReadTicket) => {
							(ticket.Site as any) = this.selectedSite;
							(ticket.Customer as any) = this.selectedSite.Customer;
						});
					}
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					this.dialogOpts.show = false;
				});
		}
	}

	UpdateUser() {
		if ((this.$refs.confirmForm as any).validate()) {
			const updateTicket = new UpdateTicket();

			updateTicket.Owner = this.selectedStatsUser.Username;
			var unLinkedTicket = Object.assign({}, this.selectedTicket);
			unLinkedTicket.Owner = this.selectedStatsUser;

			var val = updateTicket.GenerateUpdateDetails(unLinkedTicket, this.userStore.GetUser.name);

			var ids =
				this.selectedTickets.length > 0
					? this.selectedTickets.map((ticket) => {
							return ticket.TicketId;
					  })
					: [this.selectedTicket];

			this.service
				.Patch<any>('Tickets/Update', {
					noteDescription: val[0],
					ticketIds: ids,
					operations: [
						{
							op: 'replace',
							path: '/' + nameof<ReadTicket>('AzureId'),
							value: this.selectedStatsUser.AzureId,
						},
						{
							op: 'replace',
							path: '/' + nameof<ReadTicket>('Owner'),
							value: this.selectedStatsUser.Username,
						},
					],
				})
				.then(() => {
					this.selectedTickets.forEach((item) => {
						item.Owner = this.selectedStatsUser;
						item.AzureId = this.selectedStatsUser.AzureId ?? undefined;
						this.dialogOpts.show = false;
					});

					if (
						!this.staffStore.selectedUsers.some(
							(xx: IUser) => xx.AzureId === this.selectedStatsUser.AzureId
						)
					) {
						this.tickets = this.tickets.filter(
							(item) => item.AzureId !== this.selectedStatsUser.AzureId
						);
					}

					this.selectedTickets = [];
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	Track(name: string, reason: string) {
		this.$appInsights.trackEvent({
			name: name,
			properties: { reason: reason },
		});
	}

	UpdateSidebarCount() {
		for (let index = 0; index < this.selectedTickets.length; index++) {
			const element = this.selectedTickets[index];

			var groupIndex = this.staffStore.ticketCodes.findIndex((xx: any) =>
				this.staffStore.selectedTicketCodes.findIndex((yy: any) => yy.Code == xx.Code)
			);

			// var hiddenIndex = this.staffStore.selectedStatuses.findIndex(
			// 	(xx: any) => (xx.Value as any) === 9
			// );

			if (groupIndex != -1) {
				// if (this.hideUntilDate && hiddenIndex == -2) {
				// 	this.staffStore.ticketCodes[groupIndex].Hidden -= 1;
				// }
				if (element.Status.Value == TicketStatusEnum.InProgress) {
					this.staffStore.ticketCodes[groupIndex].InProgress -= 1;
					this.staffStore.ticketCodes[0].InProgress -= 1;
				} else if (element.Status.Value == TicketStatusEnum.Raised) {
					this.staffStore.ticketCodes[groupIndex].Raised -= 1;
					this.staffStore.ticketCodes[0].Raised -= 1;
				}
			}
		}

		this.hideUntilDate = undefined;
	}

	RemoveTicketsFromList() {
		var ids = this.selectedTickets.map((ticket) => {
			return ticket.TicketId;
		});

		this.tickets = this.tickets.filter((xx) => ids.indexOf(xx.TicketId) === -1);
		this.ticketsBackup = this.ticketsBackup.filter((xx) => ids.indexOf(xx.TicketId) === -1);
		this.selectedTickets = this.selectedTickets = [];
		this.ticketCount = this.ticketCount - 1;
	}

	FilterTickets(search: string) {
		if (typeof search === 'object') {
			this.tickets = this.ticketsBackup;
			return;
		}

		this.tickets = this.ticketsBackup.filter(
			(xx) =>
				xx.Description?.toLowerCase().includes(search.toLowerCase()) ||
				xx.DateCreated?.Display?.toLowerCase().includes(search.toLowerCase()) ||
				xx.CreatedBy?.toLowerCase().includes(search.toLowerCase()) ||
				xx.Owner?.Username?.toString().toLowerCase().includes(search.toLowerCase()) ||
				xx.Owner?.Email?.toString().includes(search.toLowerCase()) ||
				xx.Site?.SiteName?.toLowerCase().includes(search.toLowerCase()) ||
				xx.Site?.SiteReference?.toLowerCase().includes(search.toLowerCase()) ||
				xx.Site?.Address?.AddressLine1?.toLowerCase().includes(search.toLowerCase()) ||
				xx.Site?.Address?.Postcode?.toLowerCase().includes(search.toLowerCase()) ||
				xx.Customer?.CustomerName?.toLowerCase().includes(search.toLowerCase()) ||
				xx.Customer?.AccountNumber?.toLowerCase().includes(search.toLowerCase()) ||
				xx.ServiceItem?.Service.ServiceDescription?.toLowerCase().includes(search.toLowerCase()) ||
				xx.ServiceItem?.ServiceType?.toLowerCase().includes(search.toLowerCase()) ||
				xx.TicketId?.toString().toLowerCase().includes(search.toLowerCase()) ||
				xx.Status?.Name?.toLowerCase().includes(search.toLowerCase()) ||
				xx.ProblemCode?.Name?.toString().toLowerCase().includes(search.toLowerCase())
		);
	}

	DoubleClick(item: IReadTicket) {
		this.$router.push({
			name: 'Edit',
			query: { ticketId: item.TicketId } as any,
		});
	}

	async GetTicketDetails(item: IReadTicket) {
		if (item.ReminderDate.Value && moment().isBefore(moment(item.ReminderDate.Value))) {
			let menuItem = this.menuItems.find((xx) => xx.Name === 'Hide' || xx.Name === 'Unhide') as any;
			menuItem.Name = 'Unhide';
		} else {
			let menuItem = this.menuItems.find((xx) => xx.Name === 'Hide' || xx.Name === 'Unhide') as any;
			menuItem.Name = 'Hide';
		}

		if (this.selectedTicket != item) {
			this.ticketDetails = new ReadTicket();

			const ticket = await this.service.Get<IReadTicket>('Tickets/Details/' + item.TicketId);

			this.ticketDetails = ticket;
		}

		this.$nextTick(() => {
			// so indicator works, struggles in a regular vuejs for loop with ifs
			this.$forceUpdate();
		});

		this.showEmail = false;
		this.selectedTicket = item;
	}

	async ChangeUser() {
		this.SetSessionStorage(this.sessionStorageKeys.SelectedUsers, this.staffStore.selectedUsers);
		if (!this.staffStore.selectedUsers.length) {
			return;
		}
		if (
			this.staffStore.selectedUsers.length !== 1 &&
			!this.headers.find((xx) => xx.text === 'Owner')
		) {
			this.headers.splice(this.headers.length - 1, 0, {
				text: 'Owner',
				value: 'Owner.Username',
				width: '10%',
			} as any);
		} else if (this.staffStore.selectedUsers.length == 1) {
			this.headers = this.headers.filter((xx) => xx.text !== 'Owner');
		}

		await this.GetGroupedProblems();
	}

	async GetTickets(isPaginatedCall = false) {
		this.isTicketSearch = false;

		if (!isPaginatedCall) {
			this.ResetPagination();
			this.findTicketSearch = '';
		}

		// Currently Adds Problem Code when on All category

		this.showGridCheckboxes = false;
		this.selectedTickets = [];
		this.ticketDetails = new ReadTicket();

		if (this.searchUsers) {
			this.searchUsers = '';
		}

		let data: any = {
			Users: this.staffStore.selectedUsers.map((xx: any) => xx.AzureId),
			Codes: this.staffStore.selectedTicketCodes.map((yy: any) => yy.Code),
			Statuses: this.staffStore.selectedStatuses.map((yy: any) => yy.Value),
			PageNumber: this.options.page,
			PageSize: this.pageSize,
			SortBy: this.sortBy,
			SortDesc: this.options.sortDesc[0] ?? '',
			SearchQuery: this.searchTickets ?? '',
		};

		if (this.startDate) {
			if (!data.DateRange) {
				data.DateRange = {};
			}
			data.DateRange.Start = this.startDate;
		}
		if (this.endDate) {
			if (!data.DateRange) {
				data.DateRange = {};
			}

			data.DateRange.End = this.endDate;
		}

		try {
			let tickets = [];
			tickets = await this.service.Post<any>('Tickets/Type', data);
			this.tickets = tickets.Items;
			this.ticketsBackup = tickets.Items;
			this.ticketCount = tickets.Count;
			this.options.page = tickets.PageNumber;

			// check if ticket statuses include hidden, if they do then show hidden fields
		} catch (err) {
			console.log(err);
		}
	}

	FindTicket(isPaginatedCall = false) {
		if (this.findTicketSearch) {
			if (!isPaginatedCall) {
				this.ResetPagination();
			}
			this.isTicketSearch = true;
			this.showGridCheckboxes = false;
			this.selectedTickets = [];
			this.ticketDetails = new ReadTicket();
			this.tickets = [];
			this.service
				.Get<any>(
					'Tickets/Search/' +
						(this.findTicketSearch ?? '') +
						'?includeClosed=' +
						this.includeClosed +
						`&pageSize=${this.pageSize}&pageNumber=${this.options.page}&sortBy=${
							this.sortBy
						}&sortDesc=${this.options.sortDesc[0] ?? ''}&searchQuery=${this.searchTickets ?? ''}`
				)
				.then((tickets: any) => {
					if (tickets.Items.length === 1 && tickets.PageNumber === 1) {
						this.$router.push({
							name: 'Edit',
							query: { ticketId: tickets.Items[0].TicketId } as any,
						});
						return;
					}
					this.tickets = tickets.Items;
					this.ticketCount = tickets.Count;
					this.options.page = tickets.PageNumber;
				});
		} else {
			this.tickets = this.ticketsBackup;
		}
	}

	async created() {
		this.loadingSubscription = await isLoading$.subscribe((isLoading: boolean) => {
			this.isLoading = isLoading;
		});
		this.userData = this.$route.query;
		this.isLoading = true;

		// waiting for pinia user store return
		this.SetFilterOptions();
		this.staffStore.usersPromise.then((xx: IUser[]) => {
			this.GetGroupedProblems();
		});
	}
	sessionStorageKeys = {
		SelectedUsers: 'selectedUsers',
		SelectedStatuses: 'selectedStatuses',
		SelectedTicketCodes: 'selectedTicketCodes',
		SelectedStartDate: 'selectedStartDate',
		SelectedEndDate: 'selectedEndDate',
	};
	SetFilterOptions() {
		this.GetFromSessionStorageAndAssign(this.sessionStorageKeys.SelectedStatuses, (value) => {
			this.staffStore.selectedStatuses = value;
		});
		this.GetFromSessionStorageAndAssign(this.sessionStorageKeys.SelectedTicketCodes, (value) => {
			this.staffStore.selectedTicketCodes = value;
		});
		this.GetFromSessionStorageAndAssign(this.sessionStorageKeys.SelectedStartDate, (value) => {
			this.startDate = value;
		});
		this.GetFromSessionStorageAndAssign(this.sessionStorageKeys.SelectedEndDate, (value) => {
			this.endDate = value;
		});
		this.GetFromSessionStorageAndAssign(this.sessionStorageKeys.SelectedStartDate, (value) => {
			this.startDate = value;
		});
	}

	SetSessionStorage(key: string, val: any) {
		sessionStorage.setItem(key, JSON.stringify(val));
	}
	GetFromSessionStorageAndAssign(key: string, targetSetter: (value: any) => void) {
		const storedItems = sessionStorage.getItem(key);
		if (storedItems && JSON.parse(storedItems).length > 0) {
			const formattedStoredItem = JSON.parse(storedItems);
			targetSetter(formattedStoredItem);
		}
	}
	OnResetFiltersClicked() {
		this.searchTickets = '';
		this.findTicketSearch = '';

		// user
		const selectedUser = this.staffStore.getUsers.find(
			(xx: any) => xx.Email?.toLowerCase() === this.userStore.GetUser.username.toLowerCase()
		);
		this.staffStore.selectedUsers = [selectedUser as IUser];
		this.SetSessionStorage(this.sessionStorageKeys.SelectedUsers, this.staffStore.selectedUsers);
		//status
		this.staffStore.selectedStatuses = [
			{
				Name: TicketStatusEnum[TicketStatusEnum.Raised],
				Value: TicketStatusEnum.Raised,
			},
			{
				Name: TicketStatusEnum[TicketStatusEnum.InProgress],
				Value: TicketStatusEnum.InProgress,
			},
			{
				Name: TicketStatusEnum[TicketStatusEnum.AwaitingCustomer],
				Value: TicketStatusEnum.AwaitingCustomer,
			},
			{
				Name: TicketStatusEnum[TicketStatusEnum.AwaitingSupplier],
				Value: TicketStatusEnum.AwaitingSupplier,
			},
		];
		this.SetSessionStorage(
			this.sessionStorageKeys.SelectedStatuses,
			this.staffStore.selectedStatuses
		);
		//ticketCodes
		this.staffStore.selectedTicketCodes = [];
		this.SetSessionStorage(
			this.sessionStorageKeys.SelectedTicketCodes,
			this.staffStore.selectedTicketCodes
		);
		//startDate
		this.startDate = '';
		this.SetSessionStorage(this.sessionStorageKeys.SelectedStartDate, this.startDate);
		//endDate
		this.endDate = '';
		this.SetSessionStorage(this.sessionStorageKeys.SelectedEndDate, this.endDate);

		this.GetTickets();
	}

	async GetGroupedProblems() {
		this.isLoading = true;
		this.service
			.Post<any[]>(
				'Tickets/Counts',
				this.staffStore.selectedUsers.map((xx: IUser) => xx.AzureId)
			)
			.then((data: any) => {
				// prevents all as a ticket code being returned by the ticket codes dropdown
				this.staffStore.ticketCodes = data.filter((code: any) => code.Code !== -1);
				// if (!this.staffStore.selectedTicketCodes.length) {
				// 	this.staffStore.selectedTicketCodes.push(data[0]);
				// }
				this.searchTickets = '';
				this.GetTickets();
			});
	}

	ShowSelectSite() {
		this.dialogOpts.show = true;
		this.dialogOpts.title = 'Select Site';
		this.dialogOpts.message = 'Find sites via postcode';
		this.dialogOpts.callback = this.UpdateSite;
	}

	ShowSelectUser() {
		this.GetTeamData();
		this.dialogOpts.show = true;
		this.dialogOpts.title = 'Assign Work (Current Workload)';
		this.dialogOpts.message = '';
		this.dialogOpts.callback = this.UpdateUser;
	}

	ShowChangeUrgency() {
		this.dialogOpts.show = true;
		this.dialogOpts.title = 'Change Urgency ';
		this.dialogOpts.message = 'Choose a new ticket Urgency';
		this.dialogOpts.callback = this.UpdateUrgency;
		this.selectedUrgency = this.selectedTicket.Urgency ?? this.selectedUrgency;
	}

	GetSites(postcode: string) {
		if (this.searchSiteText && this.searchSiteText.length > 4) {
			this.service.Get<ISite[]>(`Sites/Postcode/${postcode || ''}`).then((sites) => {
				this.sites = sites;
				(document.querySelectorAll('#Select-Site input')[0] as HTMLElement).click();
			});
		}
	}

	async ShowEmail(note: IReadNote): Promise<void> {
		if (note.EmailType === EmailTypeEnum.None) {
			this.showEmail = false;
		} else if (
			this.selectedNote.CustomerNoteId &&
			this.selectedNote.CustomerNoteId !== note.CustomerNoteId
		) {
			if (note.EmailType !== EmailTypeEnum.None) {
				this.showEmail = true;
			}
		} else {
			this.showEmail = !this.showEmail;
		}
		this.selectedNote = note;

		if (this.showEmail) {
			await this.GetEmail();
		}
	}

	GetEmail(): void {
		this.emailService
			.GetEmail(this.selectedNote.CustomerNoteId, this.selectedNote.EmailType)
			.then((email) => {
				this.selectedEmail = email;
			});
	}

	GetStatusIndicatorColour(item: IReadTicket) {
		if (Object.keys(item).length === 0) {
			return '';
		} else if (moment(item.ReminderDate?.Value).diff(this.nowDate, 'days') > 1) {
			return 'var(--v-primary-base)';
		} else if (moment().diff(moment(item.DateCreated.Value), 'days') > 28) {
			return 'var(--v-secondary-base)';
		} else if (
			item.ProblemCode?.Value == TicketCodeEnum.ExtraServiceRequest ||
			item.SubCode?.Description?.toLowerCase().includes('skip')
		) {
			return 'var(--v-error-base)';
		} else if (item.Status.Value === TicketStatusEnum.Raised) {
			return 'var(--v-pink-lighten4)';
		} else {
			var statusValue = TicketStatusEnum[item.Status.Value] as any;
			var ticketColour = TicketEnumStatusColours as any;
			return ticketColour[statusValue];
		}
	}

	destroyed() {
		if (this.loadingSubscription) {
			this.loadingSubscription.unsubscribe();
		}
	}

	Navigate(params?: any) {
		this.$router.push({
			name: 'Create',
			query: params,
		});
	}

	GetTeamData(): void {
		this.isTeamDataLoading = true;
		this.service
			.Get<any[]>(`Dashboards/Tickets/Teams/Date/${RelativeDateEnum.LastYear}`)
			.then((data) => {
				const TeamData = data;
				this.isTeamDataLoading = false;

				let users = [...this.staffStore.getUsers];

				const usersWithTeams = users.map((user) => {
					const teamUserData = TeamData.find((x: any) => x.userId === user.UserId);
					const inProgress = teamUserData ? teamUserData.inProgress : 0;
					const raised = teamUserData ? teamUserData.raised : 0;

					return {
						...user,
						inProgress,
						raised,
					};
				});
				const selectedUser = users.find(
					(xx: any) => xx.Email?.toLowerCase() === this.userStore.GetUser.username.toLowerCase()
				);

				const usersInTeams: any[] = [];

				(selectedUser as any).UserGroups.forEach((userGroup: any) => {
					(usersWithTeams as any).forEach((user: any) => {
						if (
							user.UserGroups &&
							user.UserGroups.some((group: any) => group.Value === userGroup.Value)
						) {
							usersInTeams.push(user);
						}
					});
				});
				// add the five needed, it is reversed to ensure they have the correct order
				const primaryAccounts = [
					{
						FullName: 'Customer Support',
						AzureId: '4773ab24-c66c-4ad9-910b-4adbfbda30d0',
					},
					{
						FullName: 'FM Account Coordinators',
						AzureId: '21D3C39D-2F82-4E92-A84D-E633663F9412',
					},
					{
						FullName: 'FM Accounts',
						AzureId: '297babc7-fe5b-45d5-a7b6-16a5f9450905',
					},
					{
						FullName: 'Key Account Coordinators',
						AzureId: '87E712E0-357E-4C0D-97B7-AB09D7B53FD8',
					},
					{
						FullName: 'Key Accounts',
						AzureId: '652e835a-537d-4634-a93d-a591c4abdaf0',
					},
				].reverse();
				// sorts team data in alphabetical order
				this.teamData = usersInTeams.sort((a, b) =>
					a.FullName.toLowerCase().localeCompare(b.FullName.toLowerCase())
				);
				//adds the primary accounts to the options
				primaryAccounts.forEach((account) => {
					// move them if they exist as they need to be in the beginning
					if (this.teamData.map((x: any) => x.AzureId).includes(account.AzureId)) {
						let index = this.teamData.indexOf(
							this.teamData.find((x: any) => x.AzureId === account.AzureId)
						);
						this.teamData.unshift(this.teamData.splice(index, 1)[0]);
					} else {
						this.teamData.unshift(usersWithTeams.find((x: any) => x.AzureId === account.AzureId));
					}
				});
			});
	}
	AssignUser(user: any) {
		this.selectedStatsUser = this.staffStore.getUsers.find((x: any) => x.UserId === user.UserId);
	}

	ResetPagination() {
		this.options.page = 1;
		this.options.sortDesc = [];
		this.options.sortBy = [];
	}

	UpdateOptions(options: any) {
		this.options = options;
		if (options.sortBy[0] == 'TicketId') {
			this.sortBy = 'TicketId';
		} else if (options.sortBy[0] == 'ProblemCode') {
			this.sortBy = 'ProblemCode';
		} else if (options.sortBy[0] == 'ServiceItem') {
			this.sortBy = 'ServiceItem.Service.ServiceDescription';
		} else if (options.sortBy[0] == 'Customer') {
			this.sortBy = 'Customer.CustomerName';
		} else if (options.sortBy[0] == 'Site') {
			this.sortBy = 'Site.SiteName';
		} else if (options.sortBy[0] == 'DateCreated.Display') {
			this.sortBy = 'LastUpdated.Value';
		} else if (options.sortBy[0] == 'Owner.Username') {
			this.sortBy = 'Owner';
		} else if (options.sortBy[0] == 'ReminderDate.Display') {
			this.sortBy = 'ReminderDate.Value';
		} else {
			this.sortBy = '';
		}
		if (this.tickets.length || !this.isLoading) {
			this.isTicketSearch ? this.FindTicket(true) : this.GetTickets(true);
		}
	}
	HandleSearchTicketsChange(val = '') {
		if (!val) {
			this.isTicketSearch ? this.FindTicket() : this.GetTickets();
		}
	}
	HandleFindTicketsChange(val = '') {
		if (!val) {
			this.searchTickets = '';
			this.GetTickets();
		}
	}
}
</script>
