import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import ViewTickets from '@/views/Tickets//ViewTickets/Tickets.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/customersupport/',
		name: 'Home',
		component: ViewTickets,
	},
	{
		path: '/customersupport/tickets/',
		name: 'Tickets',
		// route level code-splitting
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "Tickets" */ '../views/Tickets/ViewTickets/Tickets.vue'),
		// children: [
		// 	{
		// 		path: '/customersupport/tickets/child',
		// 		name: 'Child',
		// 		component: ChildTestRoute, // Still need to find a purpose - basically a parent template with child content changing
		// 	},
		// ],
	},
	{
		path: '/customersupport/tickets/create/',
		name: 'Create',
		// route level code-splitting
		// this generates a separate chunk (CreateTicket.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "CreateTicket" */ '../views/Tickets/CreateTicket/CreateTicket.vue'
			),
		props: (route) => ({
			customerId: route.params.customerId,
			siteId: route.params.siteId,
		}),
	},
	{
		path: '/customersupport/tickets/edit/',
		name: 'Edit',
		// route level code-splitting
		// this generates a separate chunk (EditTicket.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "EditTicket" */ '../views/Tickets/EditTicket/EditTicket.vue'),
	},
	{
		path: '/customersupport/tickets/dashboard/',
		name: 'Dashboard',
		// route level code-splitting
		// this generates a separate chunk (TicketDashboard.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "TicketDashboard" */ '../views/Tickets/TicketDashboard/TicketDashboard.vue'
			),
	},
	{
		path: '/customers', // to stop redirect for microfrontends
	},
	{
		path: '/customers/*', // to stop redirect for microfrontends
	},
	{
		path: '/accounts', // to stop redirect for microfrontends
	},
	{
		path: '/supplieraccounts', // to stop redirect for microfrontends
	},
	{
		path: '/supplieraccounts/*', // to stop redirect for microfrontends
	},
	{
		path: '*',
		name: 'NotFound',
		redirect: { name: 'Tickets' },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	document.title = to.name + ' - Customer Support';
	next();
});

export default router;
