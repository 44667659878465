import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { BaseService } from '@/services/BaseService';
import { IUser } from 'greenzonegateway.classes/lib/classes';
import { userStore } from '../user/userPinia';
import { TicketCodeEnum } from 'greenzonegateway.classes/lib/classes/tickets/enums/TicketCodeEnum';
import { TicketStatusEnum } from 'greenzonegateway.classes/lib/classes/tickets/enums/TicketEnumStatus';

export const staffStore = defineStore('staff', () => {
	//Services
	const service = new BaseService();
	const userStoreLocal = userStore();
	const usersPromise = ref();

	const users = ref([] as IUser[]);
	const usersBackup = ref([] as IUser[]);
	const selectedUsers = [] as IUser[];
	const selectedStatuses = [] as {
		Value: number;
		Name: string;
	}[];

	const ticketCodes = [] as {
		Name: string;
		Raised: number;
		InProgress: number;
		Hidden: number;
		Code: TicketCodeEnum;
	}[];
	const selectedTicketCodes = [] as {
		Name: string;
		Raised: number;
		InProgress: number;
		Hidden: number;
		Code: TicketCodeEnum;
	}[];

	const ticketStatusArray = Object.keys(TicketStatusEnum)
		.filter((v) => isNaN(Number(v)))
		.map((Name: any) => {
			const val = TicketStatusEnum[Name as keyof typeof TicketStatusEnum];
			Name = Name?.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
			return {
				Value: val,
				Name,
			};
		});

	let isLoading = false;
	// watch(promise, (usersInternal: any) => {
	// 	// users = usersInternal;
	// });

	function updateUsersList() {
		console.log('fetching');
		isLoading = true;
		// this.$state.promise = getRandomJoke()
		// Will fail because we initially had a ref
		usersPromise.value = service.Get<IUser[]>('Tickets/List/Users');

		usersPromise.value.then((usersInternal: IUser[]) => {
			users.value = usersInternal;
			usersBackup.value = usersInternal;
			isLoading = false;

			const selectedUser = users.value.find(
				(xx: any) => xx.Email?.toLowerCase() === userStoreLocal.GetUser.username.toLowerCase()
			);

			const storedUsers = sessionStorage.getItem('selectedUsers');

			if (storedUsers && JSON.parse(storedUsers).length > 0) {
				const parsedUserList = JSON.parse(storedUsers).map((x: IUser) => x.UserId);

				const filteredUsers = users.value.filter((xx) => {
					return parsedUserList.includes(xx.UserId);
				});
				filteredUsers.forEach((element: IUser) => {
					selectedUsers.push(element);
				});
			} else {
				selectedUsers.push(selectedUser as IUser);
			}
			if (sessionStorage.getItem('selectedStatuses') === null) {
				const statuses = [
					{
						Name: TicketStatusEnum[TicketStatusEnum.Raised],
						Value: TicketStatusEnum.Raised,
					},
					{
						Name: TicketStatusEnum[TicketStatusEnum.InProgress],
						Value: TicketStatusEnum.InProgress,
					},
					{
						Name: TicketStatusEnum[TicketStatusEnum.AwaitingCustomer],
						Value: TicketStatusEnum.AwaitingCustomer,
					},
					{
						Name: TicketStatusEnum[TicketStatusEnum.AwaitingSupplier],
						Value: TicketStatusEnum.AwaitingSupplier,
					},
				];
				statuses.forEach((status) => {
					selectedStatuses.push(status);
				});
			} else {
				const statuses = sessionStorage.getItem('selectedStatuses') as string;
				const parsedStatuses = JSON.parse(statuses);
				parsedStatuses.forEach((status: any) => {
					selectedStatuses.push(status);
				});
			}
			if (sessionStorage.getItem('selectedTicketCodes') !== null) {
				const ticketCodes = sessionStorage.getItem('selectedTicketCodes') as string;
				const parsedTicketCodes = JSON.parse(ticketCodes);
				parsedTicketCodes.forEach((ticketCode: any) => {
					selectedTicketCodes.push(ticketCode);
				});
			}

			// switch (selectedUser?.Position) {
			// 	case 'FM Accounts': {
			// 		const support = users.value.find((x) => x.Position?.toLowerCase() === 'fm accounts');

			// 		if (support) {
			// 			selectedUsers.value.push(support as IUser);
			// 		}

			// 		break;
			// 	}

			// 	case 'Key Accounts': {
			// 		const support = users.value.find((x) => x.Position?.toLowerCase() === 'key accounts');

			// 		if (support) {
			// 			selectedUsers.value.push(support as IUser);
			// 		}

			// 		break;
			// 	}

			// 	default: {
			// 		const support = users.value.find(
			// 			(xx: any) => xx.Position?.toLowerCase() === 'customer support'
			// 		);

			// 		if (support) {
			// 			selectedUsers.value.push(support as IUser);
			// 		}

			// 		break;
			// 	}
			// }
		});
	}

	const getUsers = computed(() => {
		if (users.value.length === 0 && !isLoading) {
			isLoading = true;
			updateUsersList();
		}
		return users.value;
	});

	const getSelectedusers = computed(() => {
		return selectedUsers;
	});

	const getSelectedStatuses = computed(() => {
		return selectedStatuses;
	});

	return {
		getUsers,
		updateUsersList,
		getSelectedusers,
		getSelectedStatuses,
		usersPromise,
		selectedUsers,
		selectedStatuses,
		selectedTicketCodes,
		ticketStatusArray,
		ticketCodes,
	};
});
