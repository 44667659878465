import {
	HubConnectionBuilder,
	HttpTransportType,
	LogLevel,
	HubConnection,
} from '@microsoft/signalr';
import Vue from 'vue';
import { BehaviorSubject } from 'rxjs';

export const isConnected$ = new BehaviorSubject(false);

const connection = new HubConnectionBuilder()
	.withUrl(`${process.env.VUE_APP_SocketURL}CustomerServicesHub`, {
		accessTokenFactory: () => JSON.parse(sessionStorage.getItem('AuthDetails') || '{}').accessToken,
	})
	.configureLogging(LogLevel.Information)
	.withAutomaticReconnect()
	.build();

const startedPromise: Promise<void> | null = null;

export default {
	install(Vue: any) {
		(Vue as any).prototype.$connection = connection;

		async function start() {
			await connection
				.start()
				.then((data) => {
					Notification.requestPermission();
					//connection.invoke('JoinGroup', 'Disruption');
					isConnected$.next(true);
					console.log('connected');
					window.addEventListener('beforeunload', function (e) {
						this.alert('test');
						connection.stop();
					});
				})
				.catch((err) => {
					isConnected$.next(false);
				});
		}

		connection.onclose(() => start());

		start();
	},
};
