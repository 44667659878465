import { defineStore } from 'pinia';
import { user$, isLoggedIn$ } from '@gz/auth';
import { computed, ref } from 'vue';

export interface User {
	username: string;
	name: string;
	localAccountId: string;
}

export const userStore = defineStore('user', () => {
	const permissions = ref([] as string[]);
	const isLoggedIn = ref(false);
	const user = ref({} as User);

	function InitialiseSubscriptions() {
		isLoggedIn$.subscribe((isLoggedIn: boolean) => {
			UpdateLoggedInStatus(isLoggedIn);
		});

		user$.subscribe((user: any) => {
			if (!user) {
				return;
			}
			UpdateUser(user);
			UpdatePermissions(user.idTokenClaims.roles);
		});
	}

	async function UpdatePermissions(permissionsNew: string[]) {
		permissions.value = permissionsNew;
	}

	async function UpdateLoggedInStatus(isLoggedInNew: boolean) {
		isLoggedIn.value = isLoggedInNew;
	}

	async function UpdateUser(userNew: User) {
		user.value = userNew;
	}

	const GetUserPermissions = computed(() => permissions.value);
	const GetUser = computed(() => user.value);
	const IsLoggedIn = computed(() => isLoggedIn.value);

	return {
		GetUserPermissions,
		UpdateUser,
		UpdateLoggedInStatus,
		UpdatePermissions,
		InitialiseSubscriptions,
		GetUser,
		IsLoggedIn,
	};
});
