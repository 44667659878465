import { Put, Post, Get, Delete, Patch, GetAxios } from '@gz/api';
import { AxiosResponse } from 'axios';

export class BaseService {
	public Get<T>(url: string, baseUrlOverwrite = process.env.VUE_APP_BaseURL || null): Promise<T> {
		return Get(url, baseUrlOverwrite);
	}

	public GetAxios<T>(
		url: string,
		baseUrlOverwrite = process.env.VUE_APP_BaseURL || null,
		options: any
	): Promise<AxiosResponse<T>> {
		return GetAxios(url, baseUrlOverwrite, options);
	}

	public Delete<T>(
		url: string,
		baseUrlOverwrite = process.env.VUE_APP_BaseURL || null
	): Promise<T> {
		return Delete(url, baseUrlOverwrite);
	}

	public Post<T>(
		url: string,
		data?: T,
		baseUrlOverwrite = process.env.VUE_APP_BaseURL || null
	): Promise<T> {
		return Post(url, data, baseUrlOverwrite);
	}

	public Put<T, T2 = T>(
		url: string,
		data: T,
		baseUrlOverwrite = process.env.VUE_APP_BaseURL || null
	): Promise<T2> {
		return Put(url, data, baseUrlOverwrite);
	}

	public Patch<T>(
		url: string,
		data: T,
		baseUrlOverwrite = process.env.VUE_APP_BaseURL || null
	): Promise<T> {
		return Patch(url, data, baseUrlOverwrite);
	}
}
